const activities = [
    {
        key: 1,
        name: "Pizzéria",
        keywords: [
            "restauration",
            "pizza",
            "livraison",
            "italie",
            "restauration",
            "pâte fine",
            "four à bois",
            "mozzarella",
            "calzone",
            "margherita"
        ],
        hide: true,
        type: "commerce",
        seo: "pizzeria"
    },
    {
        key: 2,
        name: "Restauration rapide",
        keywords: [
            "fast food",
            "sandwich",
            "pizza",
            "pizzéria",
            "food",
            "resto",
            "snack",
            "snacking",
            "restauration",
            "drive",
            "sur le pouce",
            "burger",
            "takeaway",
            "fast gourmet",
            "salade bar",
            "végétarien",
            "healthy",
            "bio",
            "végétalien",
            "panini",
            "kebab",
            "sushi",
            "snacks",
            "repas à emporter",
            "cuisine rapide",
            "menus rapides",
            "hamburgers",
            "frites",
            "boissons gazeuses",
            "desserts rapides"
        ],
        hide: false,
        type: "commerce",
        seo: "restauration-rapide"
    },
    {
        key: 3,
        name: "Restaurant à thème, traditionnel",
        keywords: [
            "restaurant",
            "restauration à table",
            "cuisine locale",
            "spécialités régionales",
            "gastronomie",
            "plat maison",
            "restaurant italien",
            "steak house",
            "gastronomique",
            "bistronomique"
        ],
        hide: false,
        type: "commerce",
        seo: "restaurant-a-theme-traditionnel"
    },
    {
        key: 4,
        name: "Bar à salade",
        keywords: [
            "salade bar",
            "restauration rapide",
            "végétarien",
            "healthy",
            "sandwicherie",
            "sandwich",
            "restauration",
            "salade sur mesure",
            "healthy",
            "bio",
            "végétalien",
            "fraîcheur"
        ],
        hide: true,
        type: "commerce",
        seo: "bar-a-salade"
    },
    {
        key: 5,
        name: "Sandwicherie",
        keywords: [
            "baguette",
            "panini",
            "wrap",
            "fast-food sain",
            "sur le pouce"
        ],
        hide: true,
        type: "commerce",
        seo: "sandwicherie"
    },
    {
        key: 6,
        name: "Librairie, papeterie, loisirs créatifs",
        keywords: [
            "livre",
            "stylo",
            "cahier",
            "fourniture",
            "magazine",
            "creation",
            "fournitures scolaires",
            "matériel de dessin",
            "loisirs créatifs",
            "papeterie",
            "jeux de société",
            "puzzles",
            "carnet de croquis",
            "peinture acrylique",
            "origami",
            "activités manuelles",
            "instruments de musique"
        ],
        hide: false,
        type: "commerce",
        seo: "librairie-papeterie-loisirs-creatifs"
    },
    {
        key: 7,
        name: "Bar, brasserie, débit de boissons",
        keywords: [
            "bar",
            "brasserie",
            "cocktails",
            "bière artisanale",
            "vin",
            "happy hour",
            "terrasse",
            "pub",
            "débit de boissons",
            "tapas",
            "spiritueux",
            "mixologie"
        ],
        hide: false,
        type: "commerce",
        seo: "bar-brasserie-debit-de-boissons"
    },
    {
        key: 8,
        name: "Coffee shop, cafétéria",
        keywords: [
            "café",
            "espresso",
            "latte",
            "cappuccino",
            "barista",
            "coffee shop",
            "thé",
            "pâtisserie",
            "smoothie",
            "frappé",
            "cafétéria",
            "americano"
        ],
        hide: false,
        type: "commerce",
        seo: "coffee-shop-cafeteria"
    },
    {
        key: 9,
        name: "Boucherie, rôtisserie",
        keywords: [
            "boucherie",
            "rôtisserie",
            "viandes fraîches",
            "charcuterie",
            "barbecue",
            "grillades",
            "saucisses",
            "poulet rôti",
            "bœuf",
            "agneau",
            "porc",
            "veau",
            "artisan boucher",
            "viande bio"
        ],
        hide: false,
        type: "commerce",
        seo: "boucherie-rotisserie"
    },
    {
        key: 10,
        name: "Alimentaire, supermarché, supérette",
        keywords: [
            "supermarché",
            "supérette",
            "épicerie",
            "produits frais",
            "alimentation",
            "alimentation générale",
            "fruits et légumes",
            "commerce de proximité",
            "primeur",
            "vente directe",
            "circuit court"
        ],
        hide: false,
        type: "commerce",
        seo: "alimentaire-supermarche-superette"
    },
    {
        key: 11,
        name: "Produits surgelés",
        keywords: [
            "froid",
            "congélation",
            "surgelé",
            "alimentaire",
            "plats cuisinés surgelés",
            "légumes surgelés",
            "fruits surgelés",
            "glaces et sorbets",
            "produits de la mer surgelés",
            "viandes et volailles surgelées",
            "apéritifs surgelés",
            "pâtisseries surgelées",
            "pizzas surgelées",
            "produits bio surgelés",
            "spécialités internationales surgelées",
            "desserts surgelés"
        ],
        hide: false,
        type: "commerce",
        seo: "produits-surgeles"
    },
    {
        key: 12,
        name: "Épicerie fine",
        keywords: [
            "traiteur",
            "épicier",
            "produits du terroir",
            "chocolats artisanaux",
            "huiles d'olive",
            "vinaigres balsamiques",
            "fromages affinés",
            "charcuterie de qualité",
            "thés et cafés sélectionnés",
            "épices rares",
            "truffes",
            "caviar",
            "paniers gourmands",
            "spécialités régionales"
        ],
        hide: false,
        type: "commerce",
        seo: "epicerie-fine"
    },
    {
        key: 13,
        name: "Boulangerie, pâtisserie",
        keywords: [
            "boulangerie",
            "pâtisserie",
            "pain",
            "baguette",
            "croissant",
            "viennoiserie",
            "gâteau",
            "tarte",
            "pain au chocolat",
            "éclair",
            "macaron",
            "pain artisanal",
            "pains spéciaux",
            "spécialités locales",
            "dessert",
            "pain bio"
        ],
        hide: false,
        type: "commerce",
        seo: "boulangerie-patisserie"
    },
    {
        key: 14,
        name: "Chocolat, confiserie",
        keywords: [
            "chocolat",
            "truffes",
            "bonbons",
            "confiserie",
            "chocolatier",
            "pralinés",
            "ganache",
            "tablettes de chocolat",
            "caramels",
            "dragées",
            "pâtes de fruits",
            "chocolat bio",
            "chocolat artisanal",
            "barres chocolatées",
            "confitures",
            "nougat",
            "guimauve",
            "gâteau",
            "biscuits"
        ],
        hide: false,
        type: "commerce",
        seo: "chocolat-confiserie"
    },
    {
        key: 15,
        name: "Glaces, gauffres",
        keywords: [
            "glacier",
            "glaces artisanales",
            "gaufres belges",
            "cornets de glace",
            "sorbets",
            "desserts glacés",
            "parfums de glace",
            "crème glacée",
            "toppings pour glaces",
            "gaufres sucrées",
            "bar à glaces"
        ],
        hide: false,
        type: "commerce",
        seo: "glaces"
    },
    {
        key: 16,
        name: "Vin et boisson",
        keywords: [
            "caviste",
            "alcool",
            "vin",
            "bière",
            "vin rouge",
            "vin blanc",
            "champagne",
            "dégustation de vins",
            "caviste",
            "accords mets-vins",
            "bières artisanales",
            "spiritueux",
            "sommelier",
            "caves à vin",
            "millésimes",
            "vin bio"
        ],
        hide: false,
        type: "commerce",
        seo: "vin-et-boisson"
    },
    {
        key: 17,
        name: "Produits bio et naturels",
        keywords: [
            "épicerie",
            "nature",
            "santé",
            "naturel",
            "produits biologiques",
            "alimentation saine",
            "cosmétiques naturels",
            "éco-responsable",
            "produits sans gluten",
            "vegan",
            "agriculture biologique",
            "compléments alimentaires naturels",
            "zéro déchet",
            "produits locaux",
            "épicerie verte"
        ],
        hide: false,
        type: "commerce",
        seo: "produits-bio-et-naturels"
    },
    {
        key: 18,
        name: "Prêt-à-porter",
        keywords: [
            "pap",
            "vetement",
            "luxe",
            "couture",
            "haut de gamme",
            "tee-shirt",
            "t-shirt",
            "mode",
            "textile",
            "habillement",
            "mode durable",
            "haute couture",
            "streetwear",
            "vêtements bio",
            "boutique de mode",
            "accessoires de mode",
            "tendances vestimentaires",
            "prêt-à-porter féminin",
            "prêt-à-porter masculin",
            "collections saisonnières",
            "marques de mode",
            "fashion week",
            "jean",
            "fast fashion"
        ],
        hide: false,
        type: "commerce",
        seo: "pret-a-porter"
    },
    {
        key: 19,
        name: "Chaussures, maroquinerie",
        keywords: [
            "basket",
            "habillement",
            "mode",
            "chaussures en cuir",
            "sacs à main",
            "maroquinerie de luxe",
            "sneakers",
            "bottines",
            "portefeuilles",
            "artisanat du cuir",
            "chaussures vegan",
            "accessoires en cuir",
            "entretien du cuir",
            "marques de chaussures",
            "sacs éco-responsables",
            "cuir"
        ],
        hide: false,
        type: "commerce",
        seo: "chaussures"
    },
    {
        key: 20,
        name: "Puériculture, mode enfantine",
        keywords: [
            "enfant",
            "bébé",
            "habillement",
            "pap",
            "naissance",
            "vêtements pour bébés",
            "jouets éducatifs",
            "poussettes",
            "sièges auto",
            "mode enfantine",
            "articles de puériculture",
            "alimentation infantile",
            "sécurité bébé",
            "produits de soin pour bébés",
            "literie enfant",
            "décoration chambre bébé",
            "vêtements bio pour enfants"
        ],
        hide: false,
        type: "commerce",
        seo: "puericulture-mode-enfantine"
    },
    {
        key: 21,
        name: "Bijouterie, horlogerie",
        keywords: [
            "bijoux",
            "montre",
            "luxe",
            "bijoux fantaisie",
            "montres de luxe",
            "bagues en diamant",
            "bracelets en or",
            "pendules",
            "colliers de perles",
            "montres connectées",
            "bijoux personnalisés",
            "réparations horlogères",
            "alliances de mariage",
            "montres automatiques",
            "créations artisanales"
        ],
        hide: false,
        type: "commerce",
        seo: "bijouterie-horlogerie"
    },
    {
        key: 22,
        name: "Article de sport",
        keywords: [
            "sport",
            "basket",
            "équipement sportif",
            "vêtements de sport",
            "chaussures de sport",
            "matériel de fitness",
            "articles de natation",
            "équipement de tennis",
            "matériel de ski",
            "équipement de musculation",
            "golf",
            "tennis",
            "running"
        ],
        hide: false,
        type: "commerce",
        seo: "article-de-sport"
    },
    {
        key: 23,
        name: "Salon de coiffure, barbier",
        keywords: [
            "coiffeur",
            "salon",
            "barbe",
            "coupe",
            "cheveux",
            "barbier",
            "coiffure homme",
            "coiffure femme",
            "coupe de cheveux",
            "coloration capillaire",
            "barbier",
            "rasage traditionnel",
            "salon de beauté",
            "coiffure mariage",
            "extensions de cheveux",
            "soins capillaires",
            "produits de coiffure",
            "brushing"
        ],
        hide: false,
        type: "commerce",
        seo: "salon-de-coiffure-barber"
    },
    {
        key: 24,
        name: "Parfumerie, cosmétique",
        keywords: [
            "parfum",
            "beauté",
            "maquillage",
            "sourcil",
            "épilation",
            "parfums de luxe",
            "soins de la peau",
            "produits de beauté",
            "soins du corps",
            "produits de luxe",
            "parfums pour hommes",
            "parfums pour femmes",
            "produits de maquillage artistique",
            "coffrets cadeaux",
            "accessoires de beauté",
            "soins des cheveux"
        ],
        hide: false,
        type: "commerce",
        seo: "parfumerie-cosmetique"
    },
    {
        key: 25,
        name: "Esthetique, soin du corps",
        keywords: [
            "beauté",
            "épilation",
            "massage",
            "détente",
            "salon",
            "esthéticienne",
            "spa",
            "soins du visage",
            "soins du corps",
            "massages",
            "manucure",
            "pédicure",
            "bronzage sans UV",
            "modelage du corps",
            "soins amincissants",
            "relaxation",
            "traitement anti-âge",
            "maquillage permanent"
        ],
        hide: false,
        type: "commerce",
        seo: "esthetique-soin-du-corps"
    },
    {
        key: 26,
        name: "Complément alimentaire, diététique",
        keywords: [
            "compléments alimentaires",
            "nutrition sportive",
            "minéraux et vitamines",
            "produits de régime",
            "suppléments de protéines",
            "gestion du poids",
            "santé digestive",
            "énergie et vitalité",
            "produits detox",
            "bien-être articulaire",
            "produits bioactifs",
            "nutrition pour la peau"
        ],
        hide: false,
        type: "commerce",
        seo: "complement-alimentaire-dietetique"
    },
    {
        key: 27,
        name: "Salle de sport",
        keywords: [
            "fitness",
            "musculation",
            "salle de sport",
            "muscle",
            "gym",
            "équipements de cardio",
            "entraînement en groupe",
            "musculation",
            "cours collectifs",
            "coaching personnel",
            "machines de fitness",
            "sauna",
            "cours virtuels fitness",
            "yoga",
            "pilates",
            "zumba",
            "piscine",
            "boxe"
        ],
        hide: false,
        type: "commerce",
        seo: "salle-de-sport"
    },
    {
        key: 28,
        name: "Optique, Audition",
        keywords: [
            "opticien",
            "lunette",
            "vue",
            "écoute",
            "prothese",
            "auditive",
            "son",
            "lunetier",
            "lunettes de vue",
            "lunettes de soleil",
            "lentilles de contact",
            "examen de la vue",
            "montures de lunettes",
            "verres correcteurs",
            "lunettes anti-lumière bleue",
            "lunettes de sport",
            "lunettes pour enfants",
            "prothèses auditives",
            "appareils auditifs",
            "examen auditif"
        ],
        hide: false,
        type: "commerce",
        seo: "optique-audition"
    },
    {
        key: 29,
        name: "Solde, bazar, discount",
        keywords: [
            "discounter",
            "articles discount",
            "déstockage",
            "prix réduits",
            "bonnes affaires",
            "articles à bas prix",
            "vente en gros",
            "produits variés",
            "liquidation",
            "promotions",
            "offres spéciales"
        ],
        hide: false,
        type: "commerce",
        seo: "solde-bazar-discount"
    },
    {
        key: 30,
        name: "Pharmacie, parapharmacie, santé",
        keywords: [
            "médicaments",
            "médicaments sans ordonnance",
            "appareils médicaux",
            "produits de soins",
            "produits d'hygiène",
            "compléments alimentaires",
            "soins de la peau",
            "produits bio et naturels",
            "vitamines et minéraux",
            "dermocosmétiques",
            "huiles essentielles",
            "matériel médical",
            "conseils santé et bien-être",
            "soins pour bébés",
            "produits pharmaceutiques",
            "cosmétiques",
            "produits de première nécessité",
            "traitement des allergies"
        ],
        hide: false,
        type: "commerce",
        seo: "pharmacie-parapharmacie"
    },
    {
        key: 31,
        name: "Meubles, literie",
        keywords: [
            "mobilier",
            "aménagement",
            "décoration",
            "maison",
            "canapés",
            "tables",
            "chaises",
            "armoires",
            "lits",
            "matelas",
            "literie",
            "rangements",
            "étagères",
            "accessoires",
            "luminaires",
            "tapis",
            "rideaux",
            "cuisine",
            "salle de bains"
        ],
        hide: false,
        type: "commerce",
        seo: "meubles-literie"
    },
    {
        key: 33,
        name: "Décoration, art de la table, linge de maison",
        keywords: [
            "décoration",
            "maison",
            "linge",
            "linge de lit",
            "tendances",
            "vaisselle",
            "couverts",
            "nappe",
            "luminaires",
            "literie",
            "rangement"
        ],
        hide: false,
        type: "commerce",
        seo: "decoration-art-de-la-table-linge-de-maison"
    },
    {
        key: 34,
        name: "Bricolage",
        keywords: [
            "aménagement",
            "bricolage",
            "outillage",
            "quincaillerie",
            "jardinage",
            "rénovation"
        ],
        hide: false,
        type: "commerce",
        seo: "bricolage-travaux-batiment"
    },
    {
        key: 35,
        name: "Jardinage, jardin, espace vert",
        keywords: [
            "jardinerie",
            "plein air",
            "nature",
            "jardinage",
            "espaces verts",
            "aménagement paysager",
            "plantes",
            "fleurs",
            "arbustes",
            "pots de fleurs",
            "outils de jardinage",
            "tondeuses",
            "arrosage",
            "décoration extérieure"
        ],
        hide: false,
        type: "commerce",
        seo: "jardinage"
    },
    {
        key: 36,
        name: "Cuisine, salle de bains",
        keywords: [
            "cuisiniste",
            "cuisine",
            "salle de bains",
            "équipement",
            "ustensiles",
            "électroménager",
            "robinetterie",
            "sanitaires",
            "rangement",
            "décoration",
            "accessoires"
        ],
        hide: false,
        type: "commerce",
        seo: "cuisine-salle-de-bains"
    },
    {
        key: 37,
        name: "Entretien, réparation auto-moto",
        keywords: [
            "station service",
            "voiture",
            "réparation",
            "garage",
            "entretien automobile",
            "réparation automobile",
            "mécanique automobile",
            "pièces détachées",
            "vidange",
            "changement de pneus",
            "diagnostic moteur",
            "carrosserie",
            "peinture automobile",
            "entretien moto",
            "réparation moto",
            "accessoires auto-moto"
        ],
        hide: false,
        type: "commerce",
        seo: "entretien-reparation-automobile"
    },
    {
        key: 38,
        name: "Vélo, trotinettes, mobilité urbaine",
        keywords: [
            "vélos",
            "cyclisme",
            "équipement cycliste",
            "accessoires vélo",
            "réparation vélo",
            "entretien vélo",
            "vélos électriques",
            "vélos de route",
            "VTT",
            "vélos urbains",
            "casques",
            "vêtements de cyclisme",
            "vélos électriques",
            "trotinettes électriques",
            "accessoires de mobilité urbaine",
            "vélos pliants",
            "vélos cargo",
            "vélos de ville",
            "solutions de transport écologiques",
            "vélos hybrides",
            "systèmes de partage de vélos",
            "accessoires de sécurité pour la mobilité urbaine"
        ],
        hide: false,
        type: "commerce",
        seo: "velo-moto"
    },
    {
        key: 39,
        name: "Lavage automobile",
        keywords: [
            "station lavage",
            "karcher",
            "nettoyage",
            "lavage auto",
            "nettoyage automobile",
            "lavage haute pression",
            "lavage intérieur",
            "lavage extérieur",
            "produits de nettoyage auto",
            "aspirateurs",
            "polish",
            "cire",
            "lustrage",
            "services de detailing",
            "lavage écologique"
        ],
        hide: false,
        type: "commerce",
        seo: "lavage-automobile"
    },
    {
        key: 40,
        name: "Achat, location automobile",
        keywords: [
            "loueur",
            "automobile",
            "voiture",
            "location",
            "véhicule",
            "agence de location",
            "achat automobile",
            "vente de voitures",
            "location de voitures",
            "concessionnaire automobile",
            "véhicules neufs",
            "véhicules d'occasion",
            "financement automobile",
            "leasing",
            "services après-vente",
            "garanties automobiles"
        ],
        hide: false,
        type: "commerce",
        seo: "achat-location-automobile"
    },
    {
        key: 41,
        name: "Cadeaux, jouets",
        keywords: [
            "cadeaux",
            "jouets",
            "jeux pour enfants",
            "jeux éducatifs",
            "jeux de société",
            "peluches",
            "poupées",
            "jeux de construction",
            "véhicules miniatures",
            "jeux de plein air",
            "loisirs créatifs",
            "articles de fête"
        ],
        hide: false,
        type: "commerce",
        seo: "cadeaux-jouets"
    },
    {
        key: 42,
        name: "Informatique, fourniture de bureau",
        keywords: [
            "ordinateur",
            "pc",
            "technologie",
            "table",
            "chaise",
            "bureau",
            "bureautique",
            "informatique",
            "fournitures de bureau",
            "matériel informatique",
            "périphériques",
            "ordinateurs portables",
            "ordinateurs de bureau",
            "imprimantes",
            "consommables informatiques",
            "accessoires informatiques",
            "logiciels",
            "mobilier de bureau",
            "papeterie",
            "articles de bureau"
        ],
        hide: false,
        type: "commerce",
        seo: "informatique-fourniture-de-bureau"
    },
    {
        key: 44,
        name: "Électroménager, multimédia",
        keywords: [
            "hifi",
            "technologie",
            "high-tech",
            "électronique",
            "informatique",
            "son",
            "photo",
            "vidéo",
            "électroménager",
            "appareils électriques",
            "gros électroménager",
            "petit électroménager",
            "multimédia",
            "appareils audio",
            "appareils vidéo",
            "téléviseurs",
            "smartphones",
            "tablettes",
            "ordinateurs",
            "enceintes connectées",
            "consoles de jeu",
            "appareils photo"
        ],
        hide: false,
        type: "commerce",
        seo: "electromenager"
    },
    {
        key: 45,
        name: "Pressing, laverie",
        keywords: [
            "chemise",
            "repassage",
            "pantalon",
            "service",
            "pressing",
            "nettoyage à sec",
            "laverie automatique",
            "blanchisserie",
            "repassage",
            "détachage",
            "services de lavage professionnel",
            "lavage de textiles délicats",
            "nettoyage de vêtements spéciaux"
        ],
        hide: false,
        type: "commerce",
        seo: "pressing-laverie"
    },
    {
        key: 46,
        name: "Fleuriste",
        keywords: [
            "fleuriste",
            "fleurs fraîches",
            "bouquets",
            "arrangements floraux",
            "compositions florales",
            "plantes d'intérieur",
            "plantes extérieures",
            "fleurs coupées",
            "cadeaux floraux",
            "décorations florales",
            "événements spéciaux",
            "livraison de fleurs"
        ],
        hide: false,
        type: "commerce",
        seo: "fleuriste"
    },
    {
        key: 47,
        name: "Occasions, dépôt-vente",
        keywords: [
            "argent",
            "troc",
            "echange",
            "depot",
            "occasions",
            "dépôt-vente",
            "seconde main",
            "articles d'occasion",
            "vêtements d'occasion",
            "meubles d'occasion",
            "équipements électroniques d'occasion",
            "articles de puériculture d'occasion",
            "livres d'occasion",
            "jouets d'occasion",
            "accessoires d'occasion"
        ],
        hide: false,
        type: "commerce",
        seo: "occasions-depot-vente"
    },
    {
        key: 48,
        name: "Service funéraire",
        keywords: [
            "tombe",
            "mort",
            "enterrement",
            "service funéraire",
            "pompes funèbres",
            "organisation d'obsèques",
            "cercueils",
            "urnes funéraires",
            "transport de corps",
            "crémation",
            "inhumation",
            "cérémonies funéraires",
            "marbrerie funéraire",
            "prévoyance obsèques",
            "accompagnement des familles"
        ],
        hide: false,
        type: "commerce",
        seo: "service-funeraire"
    },
    {
        key: 49,
        name: "Animalerie, chasse, pêche",
        keywords: [
            "animaux",
            "bête",
            "chien",
            "chat",
            "poisson",
            "animalerie",
            "animaux de compagnie",
            "nourriture pour animaux",
            "accessoires pour animaux",
            "jouets pour animaux",
            "produits de toilettage pour animaux",
            "soins vétérinaires",
            "équipement de chasse",
            "articles de pêche",
            "appâts de pêche",
            "accessoires de chasse et de pêche"
        ],
        hide: false,
        type: "commerce",
        seo: "animalerie-chasse-peche"
    },
    {
        key: 51,
        name: "Crèche, garde d’enfant",
        keywords: [
            "enfant",
            "micro-creche",
            "garde",
            "crèche",
            "garderie",
            "garde d'enfants",
            "services de garde",
            "éducation préscolaire",
            "activités pour enfants",
            "jeux éducatifs",
            "soins aux nourrissons",
            "encadrement périscolaire",
            "programme éducatif",
            "développement de l'enfant"
        ],
        hide: false,
        type: "commerce",
        seo: "creche-garde-d-enfant"
    },
    {
        key: 52,
        name: "Produit Financiers, assurance, mutuel",
        keywords: [
            "banque",
            "argent",
            "produits financiers",
            "assurances",
            "mutuelles",
            "épargne",
            "placements financiers",
            "assurance vie",
            "assurance auto",
            "assurance habitation",
            "assurance santé",
            "assurance voyage",
            "assurance responsabilité civile",
            "plans de retraite",
            "prêts hypothécaires"
        ],
        hide: false,
        type: "commerce",
        seo: "produit-financiers-assurance-mutuel"
    },
    {
        key: 53,
        name: "Service à la personne",
        keywords: [
            "retraite",
            "personne agée",
            "agé",
            "aide",
            "soignante",
            "service à la personne",
            "assistance à domicile",
            "aide ménagère",
            "garde d'enfants",
            "soutien scolaire",
            "accompagnement des personnes âgées",
            "aide aux personnes handicapées",
            "jardinage",
            "bricolage",
            "livraison de courses",
            "services administratifs"
        ],
        hide: false,
        type: "commerce",
        seo: "service-a-la-personne"
    },
    {
        key: 54,
        name: "Agence d'intérim, travail temporaire",
        keywords: [
            "service",
            "embauche",
            "travail",
            "travail temporaire",
            "intérim",
            "agence d'intérim",
            "recrutement temporaire",
            "placement temporaire",
            "mission d'intérim",
            "travail flexible",
            "contrat à durée déterminée",
            "main-d'œuvre temporaire",
            "solutions de personnel temporaire",
            "remplacement temporaire"
        ],
        hide: false,
        type: "commerce",
        seo: "travail-temporaire-interim"
    },
    {
        key: 55,
        name: "Agence de voyage, tourisme",
        keywords: [
            "agence",
            "étranger",
            "avion",
            "train",
            "billet",
            "vacance",
            "voyage",
            "tourisme",
            "agence de voyage",
            "réservation de voyage",
            "séjours",
            "circuits touristiques",
            "billetterie",
            "locations de vacances",
            "croisières",
            "activités touristiques",
            "guides touristiques",
            "transport touristique"
        ],
        hide: false,
        type: "commerce",
        seo: "voyage-tourisme"
    },
    {
        key: 56,
        name: "Stockage",
        keywords: [
            "entrepot",
            "stock",
            "box",
            "garage",
            "stocker",
            "stockage",
            "self-stockage",
            "garde-meubles",
            "box de rangement",
            "entreposage",
            "solutions de stockage",
            "location d'espace de stockage",
            "déménagement",
            "archivage",
            "gestion de stock",
            "solutions logistiques"
        ],
        hide: false,
        type: [
            "commerce",
            "entrepots"
        ],
        seo: "stockage"
    },
    {
        key: 57,
        name: "Parc de loisirs indoor, outdoor",
        keywords: [
            "trampoline",
            "karting",
            "loisir",
            "jeux",
            "plaisir",
            "escape game",
            "escalade",
            "sport",
            "game",
            "aventure",
            "park",
            "parc",
            "parc de loisirs",
            "indoor",
            "outdoor",
            "divertissement familial",
            "jeux pour enfants",
            "structures gonflables",
            "trampolines",
            "parcours d'aventure",
            "attractions",
            "toboggans",
            "piscines à balles",
            "espaces de restauration",
            "anniversaires enfants",
            "animations thématiques"
        ],
        hide: false,
        type: "commerce",
        seo: "parc-de-loisirs-indoor-outdoor"
    },
    {
        key: 58,
        name: "Cigarette électronique, vapotage, CBD",
        keywords: [
            "vapoter",
            "fumer",
            "cigarette électronique",
            "vapotage",
            "e-liquides",
            "dispositifs de vaporisation",
            "accessoires de vapotage",
            "kits de démarrage",
            "résistances",
            "batteries",
            "clearomiseurs",
            "liquides au CBD",
            "produits au cannabidiol",
            "accessoires pour vapoteurs"
        ],
        hide: false,
        type: "commerce",
        seo: "cigarette-electronique"
    },
    {
        key: 59,
        name: "Crêperie",
        keywords: [
            "restauration"
        ],
        hide: true,
        type: "commerce",
        seo: "creperie"
    },
    {
        key: 60,
        name: "Sushi",
        keywords: [
            "asiatique",
            "japonais",
            "chinois",
            "food",
            "restauration"
        ],
        hide: true,
        type: "commerce",
        seo: "sushi"
    },
    {
        key: 61,
        name: "Loveshop",
        keywords: [
            "sex shop",
            "sexe",
            "adulte",
            "xxx",
            "loveshop",
            "boutique érotique",
            "sexshop",
            "articles pour adultes",
            "jouets pour adultes",
            "lingerie sexy",
            "accessoires coquins",
            "aphrodisiaques",
            "lubrifiants",
            "préservatifs",
            "produits de bien-être intime"
        ],
        hide: false,
        type: "commerce",
        seo: "loveshop"
    },
    {
        key: 62,
        name: "Pisciniste",
        keywords: [
            "pisciniste",
            "construction de piscines",
            "rénovation de piscines",
            "entretien de piscines",
            "équipement de piscines",
            "produits de traitement de l'eau",
            "accessoires de piscine",
            "sécurité piscine",
            "aménagement paysager autour de la piscine",
            "spas",
            "saunas",
            "piscine"
        ],
        hide: false,
        type: "commerce",
        seo: "pisciniste"
    },
    {
        key: 63,
        name: "Vente, réparation de téléphones",
        keywords: [
            "smartphone",
            "mobile",
            "portable",
            "téléphone",
            "appel",
            "réparation",
            "vente de téléphones",
            "réparation de téléphones",
            "smartphones",
            "accessoires de téléphonie",
            "réparation d'écrans",
            "remplacement de batteries",
            "déblocage de téléphones",
            "transfert de données",
            "diagnostic de panne",
            "vente de téléphones d'occasion"
        ],
        hide: false,
        type: "commerce",
        seo: "vente-reparation-de-telephones"
    },
    {
        key: 64,
        name: "Jeux-vidéo",
        keywords: [
            "magasins de jeux vidéo",
            "jeux vidéo",
            "consoles de jeux",
            "accessoires de jeux",
            "jeux neufs",
            "jeux d'occasion",
            "précommandes de jeux",
            "services de réparation de consoles",
            "consoles rétro",
            "jeux pour PC"
        ],
        hide: false,
        type: "commerce",
        seo: "jeux-video"
    },
    {
        key: 65,
        name: "Burger",
        keywords: [
            "fast food",
            "food",
            "restauration rapide",
            "restauration"
        ],
        hide: true,
        type: "commerce",
        seo: "burger"
    },
    {
        key: 66,
        name: "Multi-concept",
        keywords: [
            "franchisé",
            "franchise",
            "développement externe",
            "développement externalisé"
        ],
        hide: false,
        type: "commerce",
        seo: "multi-concept"
    },
    {
        key: 67,
        name: "Tabac, Presse, Loto",
        keywords: [
            "presse",
            "tabagiste",
            "cigarette",
            "tabac",
            "presse",
            "loto",
            "bar PMU",
            "jeux de grattage",
            "vente de cigarettes",
            "journaux et magazines",
            "jeux de loterie",
            "paris sportifs",
            "cigares",
            "compte nickel"
        ],
        hide: false,
        type: "commerce",
        seo: "tabac"
    },
    {
        key: 68,
        name: "Formation, conseil",
        keywords: [
            "école",
            "langue",
            "apprentissage",
            "éducation",
            "formation",
            "conseil",
            "cours particuliers",
            "coaching",
            "tutorat",
            "apprentissage",
            "enseignement privé",
            "soutien scolaire",
            "formations professionnelles",
            "développement personnel",
            "ateliers de formation",
            "programmes d'études",
            "préparation aux examens"
        ],
        hide: false,
        type: "commerce",
        seo: "formation-conseil"
    },
    {
        key: 69,
        name: "Camping",
        keywords: [
            "campeur",
            "camping",
            "caravanes",
            "tentes",
            "matériel de camping",
            "équipement de plein air",
            "accessoires de camping",
            "mobilier d'extérieur",
            "sacs de couchage",
            "réchauds",
            "lampes de camping",
            "glacières",
            "barbecues",
            "articles de randonnée"
        ],
        hide: false,
        type: "commerce",
        seo: "camping"
    },
    {
        key: 70,
        name: "Hôtel, Hôtel restaurant",
        keywords: [
            "hôtel",
            "hôtel restaurant",
            "hébergement",
            "séjour",
            "nuitée",
            "chambre d'hôtel",
            "suites",
            "services hôteliers",
            "restauration",
            "petit-déjeuner",
            "déjeuner",
            "dîner",
            "bar",
            "lounge",
            "salle de réunion",
            "événements spéciaux",
            "services de conciergerie"
        ],
        hide: false,
        type: "commerce",
        seo: "hotels-gites-chambres-d-hotes"
    },
    {
        key: 71,
        name: "Club, discothèque, musique",
        keywords: [
            "boite de nuit",
            "nightclub",
            "clubbing",
            "soirée",
            "musique"
        ],
        hide: false,
        type: "commerce",
        seo: "club-discotheque-musique"
    },
    {
        key: 72,
        name: "Industrie",
        keywords: [
            "industrie",
            "fabrication",
            "production",
            "usine",
            "ingénierie",
            "processus de fabrication",
            "fabrication sur mesure",
            "chaîne de production",
            "fabrication de machines",
            "contrôle qualité",
            "automatisation industrielle",
            "logistique industrielle",
            "gestion des opérations"
        ],
        hide: false,
        type: "entrepots",
        seo: "industriel"
    },
    {
        key: 73,
        name: "Agriculture, viticulture",
        keywords: [
            "agriculture",
            "viticulture",
            "culture de la vigne",
            "production agricole",
            "exploitation agricole",
            "récolte",
            "vendanges",
            "terres agricoles",
            "cultures fruitières",
            "cultures maraîchères",
            "élevage",
            "agriculture biologique",
            "techniques de culture",
            "fertilisation",
            "irrigation"
        ],
        hide: false,
        type: [
            "commerce",
            "entrepots"
        ],
        seo: "agriculture-viticulture"
    },
    {
        key: 74,
        name: "Immobilier",
        keywords: [
            "immobilier",
            "agence immobilière",
            "achat immobilier",
            "vente immobilière",
            "location immobilière",
            "gestion immobilière",
            "investissement immobilier",
            "promotion immobilière",
            "transactions immobilières",
            "courtage immobilier",
            "expertise immobilière",
            "gestion locative",
            "adminstrateur de biens et syndic"
        ],
        hide: false,
        type: [
            "commerce",
            "terrain"
        ],
        seo: "immobilier"
    },
    {
        key: 75,
        name: "Profession libérale",
        keywords: [
            "profession libérale",
            "indépendant",
            "travailleur autonome",
            "consultant",
            "expert",
            "praticien",
            "avocat",
            "médecin",
            "notaire",
            "comptable",
            "architecte",
            "psychologue",
            "coach",
            "conseiller"
        ],
        hide: false,
        type: [
            "commerce",
            "bureaux"
        ],
        seo: "professions-liberales"
    },
    {
        key: 76,
        name: "Transport, logistique",
        keywords: [
            "livraison",
            "chargement",
            "quai",
            "transport",
            "logistique",
            "fret",
            "expédition",
            "chaîne d'approvisionnement",
            "entreposage",
            "distribution",
            "transport routier",
            "transport maritime",
            "transport aérien",
            "transport ferroviaire",
            "transit",
            "gestion des stocks",
            "logistique inversée",
            "gestion des entrepôts"
        ],
        hide: false,
        type: "entrepots",
        seo: "transport-logistique"
    },
    {
        key: 77,
        name: "Vidéo, photo",
        keywords: [
            "photographie",
            "photographe",
            "vidéaste",
            "artistique",
            "studio",
            "vidéo",
            "photo",
            "photographie",
            "photographe",
            "vidéaste",
            "production vidéo",
            "séance photo",
            "portrait",
            "mariage",
            "événementiel",
            "retouche photo",
            "montage vidéo",
            "équipement photo",
            "caméras",
            "appareils photo",
            "objectifs"
        ],
        hide: false,
        type: "commerce",
        seo: "video-photo"
    },
    {
        key: 78,
        name: "Investissement",
        keywords: [
            "mur",
            "promotion",
            "promoteur",
            "investir",
            "investisseur",
            "investissement",
            "finance",
            "gestion de patrimoine",
            "conseil en investissement",
            "placements financiers",
            "portefeuille d'investissement",
            "gestion d'actifs",
            "fonds d'investissement",
            "marché financier",
            "bourse",
            "trading",
            "stratégies d'investissement",
            "analyse financière"
        ],
        hide: false,
        type: "investissement",
        seo: "investissement"
    },
    {
        key: 79,
        name: "Entrepôt",
        keywords: [],
        hide: true,
        type: "entrepots",
        seo: "entrepot"
    },
    {
        key: 80,
        name: "Bureaux",
        keywords: [],
        hide: true,
        type: "bureaux",
        seo: "bureaux"
    },
    {
        key: 81,
        name: "Popup-store",
        keywords: [],
        hide: true,
        type: "pop-up-store",
        seo: "investissement"
    },
    {
        key: 82,
        name: "Chambres d'hôtes, Gîtes",
        keywords: [
            "chambres d'hôtes",
            "gîtes",
            "hébergement de vacances",
            "séjour à la campagne",
            "locations saisonnières",
            "hébergement de charme",
            "tourisme rural",
            "accueil personnalisé",
            "petit-déjeuner inclus",
            "cadre pittoresque",
            "séjour en famille",
            "escapade romantique"
        ],
        hide: false,
        type: "commerce",
        seo: "chambres-d-hotes-gites"
    },
    {
        key: 83,
        name: "Auto-École",
        keywords: [
            "auto-école",
            "apprentissage de la conduite",
            "permis de conduire",
            "code de la route",
            "conduite accompagnée",
            "conduite supervisée",
            "cours de conduite",
            "formation à la sécurité routière",
            "examens de conduite",
            "stage de sensibilisation à la sécurité routière"
        ],
        hide: false,
        type: "commerce",
        seo: "auto-ecole"
    },
    {
        key: 84,
        name: "Fromagerie, crèmerie",
        keywords: [
            "fromagerie",
            "crèmerie",
            "produits laitiers",
            "fromages artisanaux",
            "laitages",
            "beurre",
            "crème fraîche",
            "yaourts",
            "spécialités fromagères",
            "dégustation de fromages",
            "plateaux de fromages",
            "assortiments de crèmes",
            "produits laitiers frais"
        ],
        hide: false,
        type: "commerce",
        seo: "fromagerie-cremerie"
    },
    {
        key: 85,
        name: "Poissonnerie",
        keywords: [
            "poissonnerie",
            "produits de la mer",
            "poissons frais",
            "fruits de mer",
            "coquillages",
            "crustacés",
            "poissons sauvages",
            "poissons d'élevage",
            "filets de poisson",
            "produits de la mer préparés",
            "spécialités de la mer"
        ],
        hide: false,
        type: "commerce",
        seo: "poissonnerie"
    },
    {
        key: 86,
        name: "Artiste",
        keywords: [
            "artiste",
            "ateliers artistiques",
            "poterie",
            "céramique",
            "sculpture",
            "peinture",
            "dessin",
            "arts plastiques",
            "création artistique",
            "cours d'art",
            "techniques artistiques",
            "exposition d'art",
            "artisanat",
            "œuvres d'art"
        ],
        hide: false,
        type: "commerce",
        seo: "artiste"
    },
    {
        key: 87,
        name: "Mercerie",
        keywords: [
            "mercerie",
            "fournitures de couture",
            "tissus",
            "fils à coudre",
            "aiguilles à coudre",
            "boutons",
            "fermetures éclair",
            "rubans",
            "dentelles",
            "patrons de couture",
            "accessoires de couture",
            "machines à coudre"
        ],
        hide: false,
        type: "commerce",
        seo: "mercerie"
    },
    {
        key: 88,
        name: "Fabrication industrielle",
        keywords: [
            "fabrication industrielle",
            "production en série",
            "processus de fabrication",
            "chaîne de production",
            "fabrication automatisée",
            "fabrication sur mesure",
            "contrôle qualité",
            "gestion de la production",
            "techniques de fabrication",
            "gestion des opérations industrielles"
        ],
        hide: false,
        type: "commerce",
        seo: "fabrication-industrielle"
    },
    {
        key: 89,
        name: "Agroalimentaire",
        keywords: [
            "agroalimentaire",
            "industrie alimentaire",
            "transformation des aliments",
            "production alimentaire",
            "sécurité alimentaire",
            "normes sanitaires",
            "qualité alimentaire",
            "chaîne d'approvisionnement alimentaire",
            "produits alimentaires",
            "emballage alimentaire",
            "distribution alimentaire",
            "contrôle qualité alimentaire"
        ],
        hide: false,
        type: "commerce",
        seo: "agroalimentaire"
    },
    {
        key: 90,
        name: "Métallurgie, mécanique industrielle",
        keywords: [
            "métallurgie",
            "mécanique industrielle",
            "travail des métaux",
            "usinage",
            "soudage",
            "chaudronnerie",
            "fabrication de pièces métalliques",
            "ingénierie mécanique",
            "conception de machines",
            "maintenance industrielle",
            "équipements industriels",
            "outillage de précision"
        ],
        hide: false,
        type: "commerce",
        seo: "metallurgie-mecanique-industrielle"
    },
    {
        key: 91,
        name: "Autre industrie",
        keywords: [],
        hide: false,
        type: "commerce",
        seo: "autre-industrie"
    },
    {
        key: 92,
        name: "Bâtiment Travaux Publics (BTP)",
        keywords: [
            "bâtiment",
            "travaux publics",
            "construction",
            "génie civil",
            "gros œuvre",
            "maçonnerie",
            "charpenterie",
            "plomberie",
            "électricité",
            "menuiserie",
            "rénovation",
            "construction de routes",
            "construction de ponts",
            "construction de bâtiments industriels",
            "construction résidentielle"
        ],
        hide: false,
        type: "commerce",
        seo: "batiment-travaux-publics"
    },
    {
        key: 93,
        name: "Carrelage, maçonnerie",
        keywords: [
            "carrelage",
            "maçonnerie",
            "revêtement de sol",
            "revêtement mural",
            "pose de carrelage",
            "dallage",
            "pavage",
            "rénovation de façade",
            "construction de murs",
            "chape",
            "jointoiement",
            "réparation de murs"
        ],
        hide: false,
        type: "commerce",
        seo: "carrelage-maconnerie"
    },
    {
        key: 94,
        name: "Charpente, menuiserie",
        keywords: [
            "charpente",
            "menuiserie",
            "fabrication de charpentes",
            "construction de toitures",
            "fabrication de fenêtres",
            "portes",
            "et escaliers en bois",
            "agencement intérieur",
            "pose de parquets",
            "fabrication de meubles sur mesure",
            "rénovation de menuiseries"
        ],
        hide: false,
        type: "commerce",
        seo: "charpente-menuiserie"
    },
    {
        key: 95,
        name: "Electricité, électronique",
        keywords: [
            "électricité",
            "électronique",
            "installation électrique",
            "réparation électrique",
            "câblage électrique",
            "mise aux normes électriques",
            "domotique",
            "alarmes",
            "éclairage",
            "système de sécurité",
            "câblage informatique",
            "maintenance électrique"
        ],
        hide: false,
        type: "commerce",
        seo: "electricite-electronique"
    },
    {
        key: 96,
        name: "Plomberie, Chauffage",
        keywords: [
            "plomberie",
            "chauffage",
            "installation de plomberie",
            "réparation de fuites",
            "débouchage de canalisations",
            "installation de chauffage central",
            "réparation de chaudières",
            "entretien de chauffe-eau",
            "pose de radiateurs",
            "installation de systèmes de climatisation"
        ],
        hide: false,
        type: "commerce",
        seo: "plomberie-chauffage"
    },
    {
        key: 97,
        name: "Peinture, vitrerie, plâtrerie",
        keywords: [
            "peinture",
            "vitrerie",
            "plâtrerie",
            "travaux de peinture intérieure et extérieure",
            "pose de revêtements muraux",
            "rénovation de façades",
            "peinture décorative",
            "pose de vitrages",
            "remplacement de vitres",
            "installation de cloisons et de faux plafonds",
            "réparation de plâtre"
        ],
        hide: false,
        type: "commerce",
        seo: "peinture-vitrerie-platrerie"
    },
    {
        key: 98,
        name: "Serrurerie, métallerie",
        keywords: [
            "serrurerie",
            "métallerie",
            "installation de serrures",
            "réparation de serrures",
            "reproduction de clés",
            "ouverture de portes",
            "fabrication de structures métalliques",
            "garde-corps",
            "portails",
            "clôtures",
            "grille de défense",
            "travaux de ferronnerie"
        ],
        hide: false,
        type: "commerce",
        seo: "serrurerie-metallerie"
    },
    {
        key: 99,
        name: "Autre production et bâtiment",
        keywords: [],
        hide: false,
        type: "commerce",
        seo: "autre-production-et-batiment"
    },
    {
        key: 100,
        name: "Association",
        keywords: [
            "association",
            "associatif",
            "organisation à but non lucratif",
            "engagement communautaire",
            "activisme",
            "bénévolat",
            "action sociale",
            "solidarité",
            "développement local",
            "projet citoyen",
            "initiatives collectives"
        ],
        hide: false,
        type: "bureaux",
        seo: "association"
    },
    {
        key: 101,
        name: "Entreprise commerciale",
        keywords: [
            "entreprise commerciale",
            "commerce de détail",
            "commerce de gros",
            "distribution",
            "vente au détail",
            "vente en gros",
            "chaîne de magasins",
            "commerce en ligne",
            "commerce international",
            "franchise",
            "réseau de distribution",
            "marketing et ventes"
        ],
        hide: false,
        type: [
            "bureaux",
            "terrain"
        ],
        seo: "entreprise-commerciale"
    },
    {
        key: 102,
        name: "Vente au détail",
        keywords: [
            "vente au détail",
            "commerce de détail",
            "magasin de détail",
            "point de vente",
            "boutique",
            "commerce de proximité",
            "magasin spécialisé",
            "grande distribution",
            "supermarché",
            "hypermarché"
        ],
        hide: false,
        type: "pop-up-store",
        seo: "vente-au-detail"
    },
    {
        key: 103,
        name: "Evenementiel",
        keywords: [
            "événementiel",
            "organisation d'événements",
            "planification d'événements",
            "gestion d'événements",
            "événements d'entreprise",
            "événements corporatifs",
            "congrès",
            "conférences",
            "salons professionnels",
            "festivals",
            "concerts",
            "cérémonies",
            "soirées spéciales",
            "événements culturels"
        ],
        hide: false,
        type: "pop-up-store",
        seo: "evenementiel"
    },
    {
        key: 104,
        name: "Négoce, distribution",
        keywords: [
            "négoce",
            "distribution",
            "commerce de gros",
            "vente en gros",
            "import-export",
            "distribution de produits",
            "logistique de distribution",
            "gestion de stocks",
            "réseau de distribution",
            "chaîne d'approvisionnement",
            "fournisseurs",
            "partenaires commerciaux",
            "grossiste"
        ],
        hide: false,
        type: "entrepots",
        seo: "negoce-distribution"
    },
    {
        key: 105,
        name: "Commerce en ligne",
        keywords: [
            "commerce en ligne",
            "vente en ligne",
            "e-commerce",
            "boutique en ligne",
            "site de commerce électronique",
            "plateforme de vente en ligne",
            "vente au détail en ligne",
            "marché en ligne",
            "commerce électronique",
            "transactions en ligne",
            "paiements sécurisés"
        ],
        hide: false,
        type: "entrepots",
        seo: "commerce-en-ligne"
    },
    {
        key: 106,
        name: "Artisan, BTP",
        keywords: [
            "artisan",
            "bâtiment et travaux publics (BTP)",
            "construction",
            "rénovation",
            "maçonnerie",
            "charpenterie",
            "menuiserie",
            "plomberie",
            "électricité",
            "peinture",
            "plâtrerie",
            "carrelage",
            "couverture",
            "serrurerie",
            "métallerie",
            "vitrerie",
            "chauffage"
        ],
        hide: false,
        type: "entrepots",
        seo: "artisan-btp"
    },
    {
        key: 107,
        name: "Autre",
        keywords: [],
        hide: false,
        type: [
            "commerce",
            "terrain",
            "entrepots"
        ],
        seo: "autre"
    }
]

export default activities